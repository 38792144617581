// html {
//     height: 100%;
// }
body {
    background-color: rgba(0, 0, 0, 0.493);
    // min-height: 100%;
}

img {
    border: 1px solid rgb(0, 0, 0);
}

/* to stop refresh of mobile page */
html  {
  overflow: hidden;
}
// stop text selection
body, div
{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
body {
  // overscroll-behavior-y: contain;
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: -1px;
	z-index: 1;
	margin: 0;
	padding: 0;
	overflow-y: hidden;
}
/* Create a pseudo-element body 
  This worked BUT it stopped the selection area of showing
*/
// #pseudobody {
// 	width:100%;
// 	height: 100%;
// 	position: absolute;
// 	top:0;
// 	z-index: 2;
// 	margin: 0;
// 	padding:0;
// 	overflow-y: auto;
// }
/* to stop refresh of mobile page */

/*
.selection {
    background: rgba(255, 217, 0, 0.11);
    border-radius: 0.1em;
    border: 2px solid rgba(98, 155, 255, 0.81);
  }
  .selected {
    background: #ff8800;
    border: 1px solid rgba(0, 0, 0, 0.671);
  }

  .box-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border: 2px solid rgba(57, 69, 204, 0.884);
    border-radius: 0.15em;
    padding: 1em 0;
  }

  .box-wrap.green,
  .box-wrap.blue {
    margin-bottom: 3em;
  }

  .sel-canvas {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
*/